export const BINGO_COLORS = [
	{
		light: "#FF3669",
		dark: "#B31A41"
	},
	{
		light: "#FF0000",
		dark: "#9C0000"
	},
	{
		light: "#F79100",
		dark: "#C7630A"
	},
	{
		light: "#FFE600",
		dark: "#DBA300"
	},
	{
		light: "#00D900",
		dark: "#008A00"
	},
	{
		light: "#3DFFC5",
		dark: "#06976C"
	},
	{
		light: "#00FFFF",
		dark: "#0087AB"
	},
	{
		light: "#0066FF",
		dark: "#0140B9"
	},
	{
		light: "#693DFF",
		dark: "#421FBC"
	},
	{
		light: "#FF00FF",
		dark: "#9E0094"
	}
];
